@import '../../assets/mixins.scss';

// CSS fix for tooltips being overlapped by annotations
.ConsiderationChart .highcharts-tooltip {
  &,
  & > span {
    // max-height: 50vh;
    // overflow-y: scroll;
    // pointer-events: auto !important;
    background-color: black;
    display: block;
  }
  & > span {
    padding: 7px;
  }
}
.highcharts-tooltip {
  pointer-events: auto !important;
}

.highcharts-yaxis-labels {
  text:last-child {
    transform: translate(10px, 0);
  }
}

/* Scrollbar styling */
/* Works on Firefox */
.stylized-scroll * {
  @include styled-scroll-bar;
}
/* End scrollbar styling*/
