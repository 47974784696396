@font-face {
  font-family: 'citiiqicons';
  src: url('../../fonts/citiiqicons.eot?67973332');
  src: url('../../fonts/citiiqicons.eot?67973332#iefix')
      format('embedded-opentype'),
    url('../../fonts/citiiqicons.woff2?67973332') format('woff2'),
    url('../../fonts/citiiqicons.woff?67973332') format('woff'),
    url('../../fonts/citiiqicons.svg?67973332#citiiqicons') format('svg');
  src: url('../../fonts/citiiqicons.ttf?67973332') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'citiiqicons';
    src: url('../font/citiiqicons.svg?67973332#citiiqicons') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'citiiqicons';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-basic-education:before {
  content: '\e800';
} /* '' */
.icon-children-and-youth:before {
  content: '\e801';
} /* '' */
.icon-collaboration:before {
  content: '\e802';
} /* '' */
.icon-connectivity-and-communication:before {
  content: '\e803';
} /* '' */
.icon-efficiency:before {
  content: '\e804';
} /* '' */
.icon-employment:before {
  content: '\e805';
} /* '' */
.icon-energy-supply:before {
  content: '\e806';
} /* '' */
.icon-environmental-stewardship:before {
  content: '\e807';
} /* '' */
.icon-equality:before {
  content: '\e808';
} /* '' */
.icon-ethical-compass:before {
  content: '\e809';
} /* '' */
.icon-excellence:before {
  content: '\e80a';
} /* '' */
.icon-fair-and-just-system:before {
  content: '\e80b';
} /* '' */
.icon-family:before {
  content: '\e80c';
} /* '' */
.icon-food-security:before {
  content: '\e80d';
} /* '' */
.icon-higher-education:before {
  content: '\e80e';
} /* '' */
.icon-healthy-lifestyle:before {
  content: '\e80f';
} /* '' */
.icon-hope:before {
  content: '\e810';
} /* '' */
.icon-innovation-and-entrepreneurship:before {
  content: '\e811';
} /* '' */
.icon-investment:before {
  content: '\e812';
} /* '' */
.icon-mobility-and-transportation:before {
  content: '\e813';
} /* '' */
.icon-political-stability:before {
  content: '\e814';
} /* '' */
.icon-public-health:before {
  content: '\e815';
} /* '' */
.icon-public-welfare:before {
  content: '\e816';
} /* '' */
.icon-resilient-infrastructure:before {
  content: '\e817';
} /* '' */
.icon-safety-and-security:before {
  content: '\e818';
} /* '' */
.icon-shelter-and-housing:before {
  content: '\e819';
} /* '' */
.icon-signature-and-identity:before {
  content: '\e81a';
} /* '' */
.icon-social-isolation:before {
  content: '\e81b';
} /* '' */
.icon-talent-pool:before {
  content: '\e81c';
} /* '' */
.icon-tourism:before {
  content: '\e81d';
} /* '' */
.icon-trade:before {
  content: '\e81e';
} /* '' */
.icon-urban-design:before {
  content: '\e81f';
} /* '' */
.icon-waste-management:before {
  content: '\e820';
} /* '' */
.icon-water-supply:before {
  content: '\e821';
} /* '' */
.icon-methodology-icon:before {
  content: '\e823';
} /* '' */
.icon-indicator-icon:before {
  content: '\e828';
} /* '' */
.icon-print-icon:before {
  content: '\e829';
} /* '' */
.icon-menu-icon:before {
  content: '\e82a';
} /* '' */
.icon-economy:before {
  content: '\e82b';
} /* '' */
.icon-people:before {
  content: '\e82c';
} /* '' */
.icon-geography:before {
  content: '\e82d';
} /* '' */
.icon-governance:before {
  content: '\e82e';
} /* '' */
.icon-admin:before {
  content: '\e82f';
} /* '' */
.icon-city-profile:before {
  content: '\e830';
} /* '' */
.icon-methodology:before {
  content: '\e831';
} /* '' */
.icon-city-scores:before {
  content: '\e832';
} /* '' */
.icon-dashboard:before {
  content: '\e833';
} /* '' */
.icon-trend:before {
  content: '\e834';
} /* '' */
.icon-currency-coins:before {
  content: '\e835';
} /* '' */
.icon-gdp-hand:before {
  content: '\e836';
} /* '' */
.icon-metro-pop-people:before {
  content: '\e837';
} /* '' */
.icon-urban-area-shape:before {
  content: '\e838';
} /* '' */
.icon-entertainment:before {
  content: '\e839';
} /* '' */
.icon-clear:before {
  content: '\e840';
} /* '' */
.icon-sdg:before {
  content: '\e900';
} /* '' */

.icon-quality-of-life {
  background-image: url('../../assets/Icons/Quality of life.svg');
  background-size: 72px 72px;
  width: 72px;
  height: 72px;

  // css override for info drawer
  .infoDrawer .drawerIconOuter &.drawerIcon {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-image: url('../../assets/Icons/Quality of life colored.svg');
  }
}

.icon-urbanMonitoringFramework {
  &::before {
    content: '';
    background-image: url('../../assets/Icons/UMF.svg');
    background-size: 1em 1em;
    width: 1em;
    height: 1em;
  }
}
