$td_cell_width: 160px;

@media print {
  * {
    min-width: 25px !important;
    color: #fff !important;
  }

  // Natural page breaks
  tr:nth-child(6n + 6) {
    page-break-after: always;
  }
}

.DataTable {
  @media print {
    max-width: 1000px !important;
  }
  table h6 {
    color: #fff;
  }
  // the overall/dimensions table (d_0)
  thead tr:nth-child(odd):hover {
    background-color: inherit !important;
  }
  &.DataTable--d_0 {
    // [class*='MuiTableHead-root'] {
    //   tr[class*='MuiTableRow-root'] {
    thead {
      tr {
        background-color: #31353d !important;
      }
    }
    // Make table row display flex so its easier to work with
    // tr[class*='MuiTableRow-root'] {
    thead tr,
    tbody tr {
      display: flex !important;
      position: relative !important;
      justify-content: space-around;
      padding-left: 245px !important;
    }
    thead tr {
      align-items: flex-start;
      min-height: 55px;
    }
    tbody tr {
      min-height: $td_cell_width;
      align-items: center;
    }
    // fix width for first column (city)
    // th[class*='MUIDataTableHeadCell-root']:first-child,
    // td[class*='MUIDataTableBodyCell-root']:nth-child(2) {
    thead th:first-child,
    tbody td:nth-child(2) {
      max-width: $td_cell_width !important;
      box-sizing: border-box;
      position: absolute;
      width: $td_cell_width !important;
      z-index: 105;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        background: #c4c4c4;
        opacity: 0.15;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        min-height: 120px;
      }
    }
    thead th:first-child {
      height: 100%;
    }
    tbody td:nth-child(2) {
      height: 100%;
    }
    // fix width for 2nd column (overall donut)
    // th[class*='MUIDataTableHeadCell-root']:nth-child(2),
    // td[class*='MUIDataTableBodyCell-root']:nth-child(4) {
    tbody td:nth-child(4) p {
      max-width: 80px !important;
      width: 80px !important;
    }
    thead th:nth-child(2),
    tbody td:nth-child(4) {
      max-width: 120px !important;
      box-sizing: border-box;
      position: absolute;
      width: 120px !important;
      z-index: 105;
      left: $td_cell_width;
      top: 0;
    }
    // column titles(labels) - align left only for 'city' column
    // th[class*='MUIDataTableHeadCell-root']:first-child {
    //   [class*='MUIDataTableHeadCell-toolButton'] {
    thead th:first-child {
      // [class*='MUIDataTableHeadCell-toolButton'] {
      & > span {
        left: 0;
        transform: unset;
        padding: 0;
        width: 100%;
      }

      // [class*='MUIDataTableHeadCell-data'] {
      & > span > div > div {
        width: 100px;
        text-align: left;
      }
    }
  }
  // the dimension/considerations table (1 - 5)
  &:not(.DataTable--0) {
    // [class*='MuiTableHead-root'] {
    //   tr[class*='MuiTableRow-root'] {
    thead {
      tr {
        background-color: #31353d !important;
      }
    }
    // Make table row display flex so its easier to work with

    // tr[class*='MuiTableRow-root'] {
    thead tr,
    tbody tr {
      display: flex !important;
      position: relative !important;
      justify-content: space-around;
      padding-left: 345px !important;
    }
    thead tr {
      min-height: 55px;
    }
    tbody tr {
      align-items: center;
      min-height: 95px;
    }
    // fix width for first column (city)
    // th[class*='MUIDataTableHeadCell-root']:first-child,
    // td[class*='MUIDataTableBodyCell-root']:nth-child(2) {
    thead th:first-child,
    tbody td:nth-child(2) {
      max-width: $td_cell_width !important;
      box-sizing: border-box;
      position: absolute;
      width: $td_cell_width !important;
      z-index: 105;
      left: 0;
      top: 0;

      align-items: center;
      display: flex;
      &:after {
        content: '';
        background: #c4c4c4;
        opacity: 0.15;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
    }
    thead th:first-child {
      height: 100%;
    }
    tbody td:nth-child(2) {
      height: 100%;
    }
    tbody td:nth-child(4) p {
      max-width: 80px !important;
      width: 80px !important;
    }
    // fix width for 2nd column (country)
    // th[class*='MUIDataTableHeadCell-root']:nth-child(2),
    // td[class*='MUIDataTableBodyCell-root']:nth-child(4) {
    thead th:nth-child(2),
    tbody td:nth-child(4) {
      max-width: $td_cell_width !important;
      width: $td_cell_width !important;
      position: absolute;
      left: $td_cell_width;
      top: 0;

      align-items: center;
      display: flex;
    }
    thead th:nth-child(2) {
      height: auto;
    }
    tbody td:nth-child(4) {
      height: 100%;
    }
    // fix width for 3nd column (dimension donut)
    // th[class*='MUIDataTableHeadCell-root']:nth-child(3),
    // td[class*='MUIDataTableBodyCell-root']:nth-child(6) {
    thead th:nth-child(3),
    tbody td:nth-child(6) {
      max-width: 95px !important;
      width: 95px !important;
      position: absolute;
      left: 250px;
      top: 0;
    }
    // column titles(labels) - align left only for 'city' & 'country' column
    // th[class*='MUIDataTableHeadCell-root']:nth-child(1),
    // th[class*='MUIDataTableHeadCell-root']:nth-child(2) {
    thead th:nth-child(1),
    thead th:nth-child(2) {
      // [class*='MUIDataTableHeadCell-toolButton'] {
      & > span {
        left: 0;
        transform: unset;
        padding: 0;
        width: 100%;
        & > div {
          width: 100%;
        }
      }
      // [class*='MUIDataTableHeadCell-data'] {
      & > span > div > div {
        text-align: left;
      }
    }
  }
  // [class*='MuiTableRow-footer'] {
  tfoot tr {
    justify-content: flex-end !important;
    span {
      color: #fff;
    }
  }
}
