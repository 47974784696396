.HexProgress {
  position: relative;
  background: #5cd9ed;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  .trdiv {
    -ms-transform: rotate(-30deg); /* IE 9 */
    -webkit-transform: rotate(-30deg); /* Safari */
    transform: rotate(-30deg);
    position: absolute;
    top: -25%;
    right: -53%;
  }
  .tldiv {
    -ms-transform: rotate(30deg); /* IE 9 */
    -webkit-transform: rotate(30deg); /* Safari */
    transform: rotate(30deg);
    position: absolute;
    top: -25%;
    left: -53%;
  }
  .brdiv {
    -ms-transform: rotate(30deg); /* IE 9 */
    -webkit-transform: rotate(30deg); /* Safari */
    transform: rotate(30deg);
    position: absolute;
    bottom: -25%;
    right: -53%;
  }
  .bldiv {
    -ms-transform: rotate(-30deg); /* IE 9 */
    -webkit-transform: rotate(-30deg); /* Safari */
    transform: rotate(-30deg);
    position: absolute;
    bottom: -25%;
    left: -53%;
  }
  .fill {
    position: absolute;
    bottom: 0;
  }
  .fillT-enter {
    transform: translateY(100%);
  }
  .fillT-enter-active {
    transform: translateY(0%);
    transition: all 1s;
  }
  .fillT-appear {
    transform: translateY(100%);
  }
  .fillT-appear-active {
    transform: translateY(0%);
    transition: all 1s;
  }
  .icon {
    position: relative;
    z-index: 10;
    font-size: 45px;
  }
}
