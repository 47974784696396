.pageWrapper {
  min-width: 320px;
  .loadScreen {
    left: 320px;
  }
}
.pageContent.current-page-dashboard,
.pageContent.dashboard-isMultiCity {
  .actions {
    float: right;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .actions__button {
      margin-left: 15px;
    }
  }
}

.dashboard-footer {
  background-color: #31353d;
  font-family: Heebo;
  color: #ffffff;
  text-align: center;
  margin-top: 5px !important;
  a {
    text-decoration: none;
  }
  .footer-btn {
    background-color: transparent;
    box-shadow: none;
    text-transform: none;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  .footer-btn:hover {
    background-color: transparent;
  }
  .footer-copyright {
    display: inline-block;
    padding: 8px 24px;
    min-width: 112px;
    color: #ccc;
    font-size: 14px;
    text-align: center;
  }
}

.components-row {
  min-height: 250px;
  .comparison-container {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    .left-div {
      text-align: left;
      span {
        font-size: 16px;
        display: inline-block;
        font-weight: 800;
        line-height: 28px;
        vertical-align: middle;
        padding-top: 3px;
      }
    }
    .right-div {
      text-align: right;
    }
  }
  .horizontal-chart-container .recharts-surface {
    overflow-y: scroll;
    max-height: 400px;
    max-width: 100%;
  }
  .dropdown-selector {
    .dropdown-select {
      border: 1px solid #4a4a4a;
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.15);
      padding-left: 7px;
    }
  }
}

.method-grid {
  display: table;
  background-image: url('../../assets/question-circle.png');
  background-size: 70% auto;
  background-position: center 75%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  @media (max-width: 959px) {
    background-image: url('../../assets/question-circle.png');
    background-size: auto 65%;
    background-position: 95% center;
  }
  .method-grid-div {
    display: table-cell;
    vertical-align: top;
    color: white;
    p {
      margin: 8px 0;
    }
    width: 75%;
    float: left;
    font-size: 27px;
    .subText {
      font-size: 14px;
    }
    @media (max-width: 959px) {
      width: 50%;
    }
    .methodology-link {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-decoration: none;
      text-transform: uppercase;
      background: rgba(30, 30, 30, 0.3);
      padding: 5px 20px;
      border-radius: 3px;
      font-weight: bold;
      transition: 0.3s ease all;
      letter-spacing: 1px;
      &:hover {
        background: #282828;
      }
    }
  }
}

.Dashboard {
  font-family: Heebo;
  color: #fff;
  .featuredIndexItem {
    width: 100%;
    padding: 14px 20px;
    font-size: 12px;
    color: #aaa;
    overflow: visible;
    .titleAlignDiv {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .title {
      padding-bottom: 5px;
      font-size: 14px;
      text-transform: uppercase;
    }
    .fIndexSubItem {
      border-left: 3px solid #6c7cf0;
      display: inline-block;
      .value {
        font-size: 20px;
        color: #fff;
      }
      padding: 0 12px;
    }
    .indexNameDiv {
      display: block;
      width: 100%;
    }
    .indexName {
      font-size: 15px;
      color: #fff;
    }
    .indexName {
      position: relative;
      display: inline-block;
      .indexHoverBalloon-tooltip {
        position: absolute;
        background-color: rgba(30, 30, 30, 0.95);
        max-width: 100vw;
        width: 300px;
        padding: 0 20px;
        border-radius: 5px;
        font-size: 85%;
        bottom: 20px;
        left: 15%;
        z-index: 1;
      }
    }
    .indexHoverBalloon {
      cursor: pointer;
      position: relative;
      display: inline-block;
      margin: 0 5px;
      opacity: 0.7;

      & + .indexHoverBalloon-tooltip {
        opacity: 0;
        display: none;
        &,
        & a {
          color: white;
        }
        &:hover {
          opacity: 1;
          display: unset;
        }
      }
      &:hover + .indexHoverBalloon-tooltip {
        opacity: 1;
        display: unset;
      }
    }

    @media (max-width: 1440px) {
      padding: 10px;
      .fIndexSubItem {
        .value {
          font-size: 18px;
          color: #fff;
        }
      }
      .indexNameDiv {
        .indexName {
          font-size: 16px;
        }
      }
    }
  }
  .dashboardHeader {
    font-weight: normal;
    padding: 15px 0;
    font-size: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: 18px;
      opacity: 0.5;
      transition: opacity 0.2s;
    }
    i:hover {
      opacity: 1;
    }
    .city-name-print {
      display: none;
      color: black;
    }
    h1 {
      margin: 0;
    }
  }
  .dashboardDescription {
    opacity: 0.7;
    width: 100%;
    max-width: 600px;
    margin-top: -5px;
    font-size: 12px;
  }
  .dimScoresHeader {
    font-size: 16px;
    font-weight: bold;
    padding: 18px;
    white-space: nowrap;
    .overall-info {
      font-size: 14px;
      opacity: 0.5;
      cursor: pointer;
      margin-left: 10px;
      transition: 0.2s;
    }
    .overall-info:hover {
      opacity: 1;
    }
  }
  .overallScore {
    text-align: center;
    .spinner {
      margin-top: 35px;
    }
    .redirect-city-scores {
      cursor: pointer;
    }
  }
  @media (max-width: 1279px) {
    .hideOnMobile {
      display: none;
    }
  }
  .featured-indices {
    margin-top: 5px !important;
  }
}

@media print {
  .pageBreak {
    display: block;
    page-break-after: always;
  }
  .Dashboard {
    padding: 8%;
    .dashboardHeader {
      .city-name-print {
        display: inline;
      }
    }
    .infoButton,
    .overall-info {
      display: none !important;
    }
    .overallScore {
      text-align: left;
      padding-left: 24px;
    }
    .overall-score {
      max-width: 100%;
      display: block;
    }
    .dimScoresHeader {
      white-space: nowrap;
      padding-left: 0;
    }
    span {
      color: black;
    }
    .method-grid {
      display: none;
    }
    .watch-list {
      display: none;
    }
    .featuredIndexItem {
      color: #444;
      border: 1px solid silver;
      .fIndexSubItem {
        .value {
          color: #444;
        }
      }
      .indexNameDiv {
        max-width: none;
        .indexName {
          color: #444;
          font-weight: bold;
        }
      }
      .titleAlignDiv {
        position: relative;
      }
      .indexScoreSummary {
        position: absolute;
        right: 0;
        top: 7px;
      }
    }
  }
  .dashboard-contents {
    display: block !important;
  }
  .watched-list-container {
    display: none;
  }
  .consideration-comparison-container {
    max-width: 650px !important;
    padding-top: 8% !important;
    .CitiIQCard {
      height: auto;
    }
    .horizontalChart {
      height: auto;
    }
    .recharts-responsive-container {
      max-width: 100% !important;
    }
    .recharts-wrapper {
      width: 100% !important;
      height: auto !important;
    }
  }
  .dashboard-comparison-chart-container {
    padding-left: 24px;
    padding-bottom: 24px;
  }
  .comparison-chart-container {
    padding-top: 8% !important;
  }
  .dashboard-footer {
    background: none;
    padding-bottom: 0 !important;
    > a {
      display: none;
    }
    .footer-copyright {
      width: 100%;
      display: block;
      float: none;
      line-height: 1.2;
      margin-bottom: 0;
      padding-bottom: 0 !important;
      min-height: 0;
      color: #444;
      text-align: center;
    }
  }
}
