@import '../../assets/mixins.scss';

html,
body {
  @include styled-scroll-bar;
}
.pageContent {
  width: -webkit-stretch;
  padding: 20px;
  padding-top: 100px;
  transition: margin 0.2s;
  overflow: auto;
  @include styled-scroll-bar;

  &:not([class*='dashboard-isMultiCity']) {
    h1 svg {
      position: relative;
      top: 4px;
    }
  }
  &:is([class*='dashboard-isMultiCity']) {
    @media (max-width: 900px) {
      padding-top: 150px;
    }
  }
  @media (max-width: 717px) {
    padding-top: 170px;
  }

  h1 {
    font-weight: normal;
    font-size: 24px;
  }
}
.fadeT-enter {
  opacity: 0;
}
.fadeT-enter-done {
  opacity: 1;
  transition: all 0.5s ease-in;
}
.fadeT-appear {
  opacity: 0;
}
.fadeT-appear-active {
  opacity: 1;
  transition: all 0.5s ease-in;
}
.goalSnackBar {
  background: #9ec73d;
  border-radius: 5px;
  padding: 20px;
  color: #fff;
  font-family: Heebo;
}
.snackbarError {
  background: #e85866;
}
#mainHeader {
  flex-direction: row !important;
  padding-top: 5px;
  padding-bottom: 5px;

  @media (max-width: 900px) {
    flex-direction: column !important;
  }
}

.appBar {
  background-color: #000000 !important;
  display: flex;
  flex-direction: row;
  padding-left: 16px !important;
  padding-right: 16px !important;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  transition: 0.3s;
  font-family: Heebo;
  .toolBar {
    width: 100%;
  }
  @media (max-width: 980px) {
    .toolBar {
      padding-left: 0 !important;
      padding-right: 0 !important;
      flex-wrap: wrap;
    }
  }
  .appBarContentLeft {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .appBarContentRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    margin-left: 20px;
    max-width: 500px;
    font-weight: 400;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.73);
    @media (max-width: 980px) {
      text-align: left;
      max-width: unset;
      margin-left: 0;
    }
    @media (max-width: 717px) {
      width: 100%;
    }
  }

  .headerContentContainer {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .cityAttributes {
    display: inline-flex;
    align-items: center;
    a {
      color: inherit;
    }
    .weatherInfo {
      padding: 6px 0px 5px 5px;
      display: inline-flex;
      text-align: center;
      height: 77px;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      .weatherText {
        line-height: 100%;
      }
    }
    .weatherIcon {
      text-align: center;
      width: 44px;
      height: 29px;
    }
    .attrName {
      font-size: 12px;
      text-align: center;
      color: #ccc;
    }
    .attribute {
      padding: 6px 10px;
      display: inline-block;
      text-align: center;
      height: inherit;
      .numberText {
        font-size: 16px;
        color: #ffffff;
      }
      .subText {
        font-size: 12px;
        color: #ffffff;
      }
      i {
        font-size: 20px;
      }
    }
    .info {
      opacity: 0.5;
      height: 100%;
      i {
        position: relative;

        font-size: 18px;
        cursor: pointer;
        transition: 0.3s;
      }
    }
    .info:hover {
      opacity: 1;
    }

    @media (max-width: 599px) {
      .attribute {
        padding: 12px 0px 12px 0px;
        display: inline-block;
        text-align: center;
        .smallText {
          font-size: 14px;
          color: #aaa;
        }
        i {
          font-size: 22px;
        }
      }
    }
  }
  @media (max-width: 925px) {
    .cityAttributes {
      display: inline-flex;
      align-items: center;
      max-height: 77px;
      a {
        color: inherit;
      }
    }
  }
  @media (max-width: 600px) {
    .cityAttributes {
      width: 100%;
      justify-content: space-around;
    }
  }
}

$appbarCityMenuWidth: 326px;

.appBarMenuContainer {
  position: 'relative';
  width: $appbarCityMenuWidth;
}
.appbarCityMenu {
  display: flex;
  flex: 1;
  width: $appbarCityMenuWidth;
}
.appbarCityMenuOpen {
  background: #31353d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  max-width: $appbarCityMenuWidth;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  cursor: pointer;
}
.appbarCityMenuDropdown {
  margin-top: 65px;
  width: $appbarCityMenuWidth;
  & > div {
    // these 2 lines help with the dropdown menu matches the menu button
    // and making sure the style is consistent in WIN and MAC
    max-width: unset !important;
    width: 100% !important;
  }
  .appbarCityMenuItem {
    box-sizing: border-box;
    width: $appbarCityMenuWidth;
    height: auto;
    &.selected {
      background: #22252c;
    }
  }
}
.appbarCityMenuItemWrapper {
  margin: 0 30px;
  .appbarCityMenuItemContent {
    cursor: unset;
  }
}
.appbarCityMenuItemContent {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.2s ease background;
  white-space: nowrap;

  .appbarCityName {
    margin-left: 15px;
    margin-right: 15px;
    max-width: 200px;
    overflow: scroll;
    @include styled-scroll-bar;

    .city {
      font-size: 22px;
    }
    .country {
      font-size: 15px;
      opacity: 0.6;
    }
  }
}
.shuffleRight {
  padding-left: 310px !important;
}
.sideDrawer {
  border-right: none !important;
}
.profileIcon {
  font-size: 50px !important;
  opacity: 0.5;
  margin: 0 !important;
}
.sideMenuDiv {
  width: 35px;
  height: 35px;
  background: #555;
  text-align: center;
  font-size: 27px;
  border-radius: 50%;
  position: relative;
  z-index: 10;
  margin-right: 15px;
}
.subMenu {
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}
.sideMenuIcon {
  padding-top: 6px;
}
.sidebarIndex {
  position: absolute !important;
  bottom: 20px;
  width: 100%;
  font-family: Heebo;
  .featuredIndexItem {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 11px;
    color: #aaa;
    .fIndexSubItem {
      border-left: 3px solid #6c7cf0;
      .value {
        font-size: 18px;
        color: #fff;
      }
      padding: 0 12px;
    }
    .indexName {
      font-size: 14px;
      color: #fff;
    }
  }
  @media (max-width: 599px) {
    .featuredIndexItem {
      padding: 8px;
    }
  }
}
.sidebarWrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  .siteLogo {
    cursor: pointer;
    transition: 0.2s;
  }
  .siteLogo:hover {
    opacity: 0.85;
  }
  .sidebarBackground {
    opacity: 1;
    top: 0;
    z-index: -1;
    left: 0;
    position: absolute;
  }
  .sidebarContent {
    color: #fff;
    background: rgba(49, 53, 61, 0.94);
    letter-spacing: 0.5px;

    .sidebarText {
      color: #fff;
      font-weight: lighter;
      font-size: 14px;
    }
    .darkened {
      background-color: rgba(0, 0, 0, 0.25);
      transition: 0.3s;
    }
    .sidebarText {
      color: #fff;
      font-weight: normal;
    }
    .bolded {
      font-weight: bold;
    }
  }
}
.MuiSnackbar {
  position: absolute !important;
  max-width: 40%;
  top: calc(100% + 24px) !important;
  z-index: 1000000;
  &:after {
    content: '';
    // triangle
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 7px 7.5px;
    border-color: transparent transparent white transparent;
    // positioning
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(1px);
  }
  & > div {
    max-width: 100%;
    min-width: unset;
  }
}

.NewMuiSnackbar {
  position: fixed !important;
  max-width: fit-content;
  top: 95px !important;
  z-index: 10000;
  left: 240px !important;
  transform: none !important;
  @media (max-width: 980px) {
    left: 5px !important;
    // max-width: fit-content;
  }
  &:after {
    content: '';
    // triangle
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 7px 7.5px;
    border-color: transparent transparent white transparent;
    // positioning
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(1px);
  }
  & > div {
    max-width: 100%;
    min-width: unset;
  }
}
@media print {
  .pageWrapper {
    .appBar {
      display: none;
    }
  }
}
