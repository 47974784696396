.IntroDialog {
  padding: 30px;
  color: #fff;
  font-family: Heebo;
  width: 95%;
  transition: 0.3s;
  min-height: 490px;
  max-width: 400px;
  position: relative;
  margin: 0 !important;
  img {
    width: 100%;
    margin-bottom: 15px;
  }
  .smallImg {
    display: block;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .fadeT-enter {
    opacity: 0;
  }
  .fadeT-enter-done {
    opacity: 1;
    transition: all 0.3s ease-in;
  }
  .fadeT-appear {
    opacity: 0;
  }
  .fadeT-appear-active {
    opacity: 1;
    transition: all 0.3s ease-in;
  }
  .header {
    font-size: 40px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    text-align: center;
  }
  .subHeader {
    display: block;
    width: 100%;
    text-align: center;
    opacity: 0.5;
  }
  .body {
    display: block;
    width: 100%;
    padding-bottom: 45px;
    text-align: center;
    font-size: 19px;
  }
  .footer {
    position: absolute;
    bottom: 8px;
    right: 15px;
    color: #5e5ef9;
    cursor: pointer;
    .back {
      color: #888;
    }
    span {
      padding: 5px;
    }
  }
  @media (max-width: 599px) {
    .header {
      font-size: 30px;
    }
    .body {
      font-size: 15px;
    }
  }
}
