.navigation {
  color: #fff;
  text-decoration: none;
  font-family: Heebo;
}
.profile-container {
  border-radius: 3px;
  background-color: #31353d;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  font-family: Heebo;
  .profile-top-section {
    color: white;
    background: url('../../assets/default-profile-banner.png') center center;
    background-repeat: no-repeat;
    background-size: cover;
    .profile-top-container {
      margin: 30px;
    }
  }

  .tabs-selector {
    .tabs-options {
      font-family: Heebo;
      text-transform: capitalize;
      font-size: 12px;
      opacity: 0.4;
      color: #cbcbcb;
      svg {
        color: #5d92f4;
      }
    }
    .selectedTab {
      opacity: 1;
      color: #5d92f4;
    }
  }
  .tab-indicator {
    background-color: #5d92f4;
  }
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .update-btn {
    background-color: #5d92f4;
    color: white;
  }
  .update-btn:hover {
    background-color: #5d92f4;
    color: white;
  }
  .container-title {
    color: white;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }
  .cities-table {
    .container-title {
      padding: 0px;
    }
  }
  .input-label {
    color: #b6bbcf;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
    padding: 10px;
  }
  .input-field {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    background-color: #31353d;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.13);
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
  }

  .input-field:disabled {
    border: 1px solid #6e7076;
    color: #6e7076;
  }

  .input-row {
    margin-bottom: 30px;
  }
}
