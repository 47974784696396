.goalsDialog {
  margin: 0 !important;
}
.DimensionDashboard {
  font-family: Heebo;
  color: #fff;
  @media (max-width: 570px) {
    padding-bottom: 15px;
  }
  &.DimensionDashboard--MultiCity {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto !important;
    flex-wrap: wrap;

    .dimensionScore {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .loadingDiv {
    width: 100%;
    text-align: center;
    height: 156px;
    .spinner {
      position: relative;
      top: 40px;
    }
  }
  .fadeT-enter {
    opacity: 0;
  }
  .fadeT-enter-done {
    opacity: 1;
    transition: all 0.5s ease-in;
  }
  .fadeT-appear {
    opacity: 0;
  }
  .fadeT-appear-active {
    opacity: 1;
    transition: all 0.5s ease-in;
  }

  .dashboardHeader {
    font-weight: normal;
    padding: 15px 0;
    font-size: 25px;
    align-items: flex-start;
  }
  .toggleGoals {
    position: absolute;
    right: 5px;
    top: 11px;
    display: inline-block;
  }
  .allButton {
    cursor: pointer;
    transition: 0.2s;
  }
  .allButton:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .dimensionScore {
    width: 20%;
    display: inline-block;
    padding: 20px;
    text-align: center;
    transition: background 0.3s;
    @media (max-width: 767px) {
      width: 33.33%;
    }
    @media (max-width: 570px) {
      width: 100%;
      text-align: left;
      padding: 8px 20px;
      .dimensionSubText {
        padding: 15px;
      }
    }
  }

  .dimScoresHeader {
    font-size: 16px;
    padding: 18px;
    position: relative;
    white-space: nowrap;
    .infoButton {
      max-width: 40px;
      min-width: 0;
      margin-left: 10px;
      position: relative;
      font-size: 14px;
      opacity: 0.5;
      top: -2px;
      left: -3px;
      transition: opacity 0.2s;
      cursor: pointer;
    }
    .infoButton:hover {
      opacity: 1;
    }
  }
  .dimensionSubText {
    padding: 15px 0;
    font-weight: bold;
    @media (max-width: 570px) {
      display: inline-block;
      padding: 15px;
    }
  }
  .underlined {
    border-bottom: 3px solid #9ec73d;
  }
  .overallScore {
    text-align: center;
    @media (max-width: 1279px) {
      display: none;
    }
  }
  .donut {
    @media (max-width: 570px) {
      display: inline-block;
    }
  }
}
.DimensionDashboard.selectable {
  .dimensionScore {
    cursor: pointer;
    overflow: hidden;
  }
  .dimensionScore:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
.infoDialog {
  width: 90%;
  height: 80%;
  margin: auto;
  max-width: 900px;
  color: #fff;
  font-family: Heebo;
  .widen {
    max-width: none !important;
    width: 80%;
  }
  .infoDialogClose {
    cursor: pointer;
    padding: 10px 15px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px 30px;
    font-size: 20px;
  }
  .infoDialogInner {
    width: 100%;
    height: 100%;
    padding: 20px;
    position: relative;
    .contentWrapper {
      overflow: auto;
      max-height: 83%;
      height: 75%;
    }
    .flavorText {
      font-size: 14px;
      color: #aaa;
      display: flex;
      align-items: center;
      height: 5%;
      span {
        padding-left: 10px;
      }
    }
    .subTitle {
      margin-top: 0;
    }
    .title {
      margin-top: 0px;
      margin-bottom: 5px;
      height: 10%;
    }
    .description {
      color: #bbb;
    }
    .description + .subTitle {
      margin-top: 18px;
    }
    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      color: #5d92f4;
      user-select: none;
      @media only screen and (max-width: 375px) {
        font-size: 14px;
      }
      .goLeft {
        position: absolute;
        left: 0;
        bottom: 0;
        cursor: pointer;
      }
      .goRight {
        position: absolute;
        right: 35px;
        bottom: 0;
        cursor: pointer;
      }
    }
  }
}
.goalsDialogInnerDiv {
  padding: 20px;
  color: #fff;
  font-family: Heebo;
  width: 600px;
  max-width: 100%;
  @media (max-width: 520px) {
    .dimensionName {
      display: none;
    }
  }
  .saveBtn {
    text-align: right;
    padding-bottom: 10px;
    span {
      color: #4a90e2;
      cursor: pointer;
      font-weight: bold;
      position: relative;
      top: 8px;
      right: 15px;
    }
  }
  .header {
    font-weight: bold;
    color: #aaa;
    position: relative;
    top: -10px;
    @media (max-width: 520px) {
      font-size: 13px;
    }
  }
  .centreText {
    text-align: center;
  }
  .goalItem {
    padding: 5px 0;
    display: flex;
    position: relative;
    align-items: center;
    .innerDonutText {
      top: 0;
    }
    .donutWrapper {
      margin-right: 8px;
    }
    .goalInput {
      display: inline-block;
      position: absolute;
      right: 10px;
      width: 250px;
      display: flex;
      justify-content: flex-end;
      .clearIcon {
        position: absolute;
        right: -20px;
        top: 10px;
        font-size: 14px;
        color: #aaa;
      }
      .clearIcon:hover {
        color: #fff;
        cursor: pointer;
      }
      span {
        width: 60px;
        margin-right: 20px;
        display: inline-block;
        text-align: center;
      }
      @media (max-width: 500px) {
        width: 210px;
        .clearIcon {
          right: -12px;
        }
      }
    }
    .input {
      width: 40px;
      margin-right: 20px;
      text-align: center !important;
    }
    .select {
      width: 40px;
      text-align: center !important;
    }
    .wide {
      width: 80px;
    }
    .marginRight {
      margin-right: 20px;
    }
  }
}
@media (max-width: 600px) {
  .infoDialog {
    .infoDialogInner {
      padding: 10px;
      max-width: 100% !important;
      max-height: 100% !important;
      height: 100%;
      margin: 0;
      .contentWrapper {
        max-height: 80%;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 5px;
      }
    }
  }
}

@media print {
  .DimensionDashboard {
    color: #444;
    padding-left: 24px;

    .dimensionScore {
      width: 33.3%;
    }
  }
}
