@import './assets/mixins.scss';

$blue: #34ace0;
$dark_blue: #00aeef;
$light_blue: #71c6eb;

$purple: #764b9e;
$light_purple: #9163bb;

$yellow: #e8961b;
$light_yellow: #fbb03f;

$green: #5cc19c;
$light_green: #7ddab8;

$pink: #ef3488;
$light_pink: #fa67a9;

$font-color: #444;

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.actions {
  float: right;

  i {
    cursor: pointer;
  }
}

@media print {
  body,
  html {
    overflow: hidden;
  }
  .sidebarWrapper {
    display: none;
  }
  .pageContent {
    margin-left: 0 !important;
    padding-bottom: 0;
  }

  .Dashboard {
    color: $font-color;
  }
  // *[class*="MuiPaper-root"] {
  //   background: #FFF !important;
  //   box-shadow: none !important;
  // }
  .actions {
    display: none;
  }

  .components-row .dropdown-selector,
  .dropdown-selector {
    .dropdown-select {
      border: none;
      background: none;
      border-radius: 0;
      text-decoration: underline;
      color: #444;
    }
  }

  .recharts-responsive-container {
    max-width: 680px;

    .recharts-wrapper {
      max-width: 100%;
    }
  }
}

// PRINT STYLES
@page {
  margin: 0;
}
body {
  margin: 0;
}
.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

/** Paper sizes **/
body.A3 .sheet {
  width: 297mm;
  height: 419mm;
}
body.A3.landscape .sheet {
  width: 420mm;
  height: 296mm;
}
body.A4 .sheet {
  width: 210mm;
  height: 296mm;
}
body.A4.landscape .sheet {
  width: 297mm;
  height: 209mm;
}
body.A5 .sheet {
  width: 148mm;
  height: 209mm;
}
body.A5.landscape .sheet {
  width: 210mm;
  height: 147mm;
}
body.letter .sheet {
  width: 216mm;
  height: 279mm;
}
body.letter.landscape .sheet {
  width: 280mm;
  height: 215mm;
}
body.legal .sheet {
  width: 216mm;
  height: 356mm;
}
body.legal.landscape .sheet {
  width: 357mm;
  height: 215mm;
}

/** Padding area **/
.sheet.padding-10mm {
  padding: 10mm;
}
.sheet.padding-15mm {
  padding: 15mm;
}
.sheet.padding-20mm {
  padding: 20mm;
}
.sheet.padding-25mm {
  padding: 25mm;
}

/** For screen preview **/
@media screen {
  body {
    background: #e0e0e0;
  }
  .sheet {
    background: white;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
    margin: 5mm auto;
  }
}

/* Scrollbar styling */
/* Works on Firefox */
.stylized-scroll * {
  @include styled-scroll-bar;
}
/* End scrollbar styling*/

/** Fix for Chrome issue #273306 **/
@media print {
  body.A3.landscape {
    width: 420mm;
  }
  body.A3,
  body.A4.landscape {
    width: 297mm;
  }
  body.A4,
  body.A5.landscape {
    width: 210mm;
  }
  body.A5 {
    width: 148mm;
  }
  body.letter,
  body.legal {
    width: 216mm;
  }
  body.letter.landscape {
    width: 280mm;
  }
  body.legal.landscape {
    width: 357mm;
  }
}
