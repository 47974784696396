.pageContent.dashboard-isMultiCity {
  .dashboardHeader {
    flex-flow: wrap;
  }
  .dashboardTitle {
    display: flex;
    align-items: center;
    h1 {
      font-size: 24px;
    }
  }
  .multiCityFilterChip {
    background-color: rgba(0, 0, 0, 0);
    color: #9ec73d;
    margin: 0 10px;
    font-size: 14px;
    &.disabled {
      opacity: 0.5;
      color: inherit;
    }
  }
  .dimension-buttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .dimension-buttons__button {
    text-transform: capitalize;
    margin: 10px;
    flex: 1;
    flex-shrink: 1;
    white-space: nowrap;
    &.active {
      background-color: #22252c;
      color: #fff;
    }
  }
  .dimension-buttons__button__dot {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 100%;
    flex-shrink: 0;
  }
  .dimension-select {
    display: none;
    width: calc(100% - 35px);
    &:before {
      display: none !important;
    }
  }
  .dimension-select__input {
    padding: 15px 40px 15px 20px;
    margin: 10px 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #22252c;
    color: #fff;
    border-radius: 4px;
  }
  .considerationsTableTitle--desktop {
    display: block;
  }
  .considerationsTableTitle--mobile {
    display: none;
  }
  .overallDonutsCard {
    height: auto;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    .overallScore,
    .dimensionScore {
      width: calc(100% / 6);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 25px 10px;
    }
    .dimensionScore {
      flex-direction: column-reverse;
      height: 75%;
      box-sizing: border-box;
      position: relative;
      padding-top: 0;
      margin-top: 65px;
      cursor: pointer;
      .innerDonutText {
        font-size: 21px !important;
      }
      .topScoreTitle {
        bottom: 100%;
        margin-bottom: -5px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
      }
    }
    .donutWrapper {
      margin: 10px 0 5px;
    }
    .topScoreTitle {
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      opacity: 0.5;
      margin: 2px 0;
      font-weight: 700;
    }
    .topScoreCity {
      font-size: 19px;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
      margin: 10px 0 2px;
      font-weight: normal;
      white-space: nowrap;
    }
    .topScoreCountry {
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: rgba(255, 255, 255, 0.73);
      opacity: 0.7;
      margin: 2px 0;
      font-weight: normal;
    }
  }
  .considerationsTableButtons {
    display: flex;
    justify-content: flex-end;
  }
  .considerationsTableButton {
    float: right;
    color: white;
    padding: 4px;
    margin: 0 4px;
    min-width: 40px;
    &.active {
      opacity: 0.3;
      background-color: #1e1d1f;
    }
  }
}
// tablet
@media only screen and (max-width: 768px) {
  .pageContent.dashboard-isMultiCity {
    .overallDonutsCard {
      .overallScore,
      .dimensionScore {
        width: calc(100% / 3);
      }
      .dimensionScore {
        height: auto;
        padding-top: 25px;
        margin: 4px 0;
        .topScoreTitle {
          position: static;
          transform: unset;
          margin: 0;
          width: unset;
        }
      }
    }
  }
}
// mobile
@media only screen and (max-width: 620px) {
  .pageContent.dashboard-isMultiCity {
    .dashboard-contents {
      .dimension-buttons__button {
        display: none !important;
      }
      .dimension-select {
        display: block !important;
      }
    }
    .considerationsTableTitle--desktop {
      display: none !important;
    }
    .considerationsTableTitle--mobile {
      display: block !important;
    }
    .dashboardTitle {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
      h1 {
        font-size: 20px;
      }
    }
    .multiCityFilterChip {
      margin-left: 0;
      margin-right: 0;
      & span {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

// tablet
@media only screen and (max-width: 425px) {
  .pageContent.dashboard-isMultiCity {
    .overallDonutsCard {
      .overallScore,
      .dimensionScore {
        width: calc(100% / 2);
        padding: 15px 10px;
      }
      .dimensionSubText {
        height: 27px;
        margin-bottom: 5px;
        display: flex;
        align-items: flex-end;
      }
      .overallScore .topScoreTitle {
        margin-bottom: 5px;
      }
    }
  }
}
