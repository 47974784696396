.Methodology {
  font-family: Heebo;
  color: #ffffff;

  .navigation {
    color: white;
    text-decoration: none;
  }

  .anchor-item {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }
  .contents-container {
    padding-left: 5%;
  }

  .img-responsive {
    width: unset;
    max-width: 100%;
    height: auto;
  }

  .content-text {
    color: rgba(255, 255, 255, 0.73);
    font-family: Heebo;
    font-size: 16px;
    line-height: 24px;
  }
  .content-header {
    color: #ffffff;
    font-size: 24px;
    line-height: 32px;
  }
  .content-sub-header {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 40px;
  }
  .img-text {
    margin-top: 10px;
  }

  .source-list {
    list-style-position: inside;
    column-count: 2;
    @media (max-width: 580px) {
      column-count: 1;
      font-size: 13px;
      padding-left: 0px;
    }
  }

  .contentsAnchor {
    .anchor-text {
      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;
      line-height: 16px;
      padding-left: 16px;
    }
    .anchor-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      border-left: 2px solid #ffffff;
    }
    .anchor-title:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }
}
