.IndicatorDialog {
  padding: 30px;
  color: #fff;
  font-family: Heebo;
  width: 100%;
  max-width: 400px;
  position: relative;
  margin: 0 !important;
  overflow: visible;
  .closeBtn {
    display: none;
    position: relative;
    top: -15px;
    left: -15px;
    opacity: 0.5;
  }
  .print-icon {
    float: right;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.4s;

    &:hover {
      opacity: 1;
    }
  }
  .action-btns {
    overflow: hidden;
  }
  .icon {
    color: #9ec73d;
  }
  .header {
    font-size: 40px;
    padding-bottom: 35px;
  }
  .title {
    font-weight: bold;
  }
  .description {
    color: #ccc;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-bottom: 40px;
  }
  .sources {
    margin-top: 40px;
    font-size: 14px;
    .title {
      font-size: 14px;
    }
  }
  .source {
    color: #ccc;
    font-size: 12px;
  }
  .grade {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    transition: background 0.2s;
    padding: 4px;
    margin: -4px;
    border-radius: 5px;
    .gradeDescription {
      display: inline-block;
      font-weight: lighter;
      max-width: 60%;
      color: #ccc;
      font-size: 14px;
      padding-left: 20px;
      div {
        padding: 4px;
      }
    }
    .gradeDescTitle {
      font-weight: bold;
      color: #fff;
      font-size: 16px;
    }
  }
  .grade:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .bottomMargin {
    margin-bottom: 35px;
  }
  @media (max-width: 959px) {
    max-height: none !important;
    height: 100%;
    max-width: none !important;
    width: 100%;
    .closeBtn {
      display: inherit;
    }
    .header {
      font-size: 30px;
      padding-bottom: 15px;
    }
    .bottomMargin {
      margin-bottom: 15px;
    }
  }
}

// Print
.letter {
  padding: 8%;

  .action-btns {
    display: none;
  }

  .header {
    span {
      display: block;
      font-size: 40px;
    }
  }

  .donutWrapper {
    float: left;
    width: 100px;
    margin-right: 20px;
  }
  .grade {
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .gradeDescription {
    float: left;
    width: 450px;
    padding-top: 20px;

    .gradeDescTitle {
      font-weight: bold;
      font-size: 16px;
      display: block;
      margin-bottom: 5px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .impacted-considerations-container {
    display: block;
    overflow: hidden;

    .impacted-considerations-wrapper {
      width: 33%;
      float: left;
      display: block;
    }
    .impacted-consideration-item {
      .donutWrapper {
        float: left;
      }
      .gradeDescription {
        width: 100px;

        .gradeDescTitle {
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }

  .sources {
    .title {
      margin-bottom: 10px;
    }
  }
  .sources-container {
    .source {
      font-size: 12px;
    }
  }
}
