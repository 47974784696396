.infoDrawer {
  color: #ddd;
  font-family: Heebo;
  padding: 8%;
  background: #282729;
  overflow: auto;
  .scrollRef {
    margin-top: -30px;
  }
  .rightAlignContents {
    text-align: right;
  }
  .drawerTitle {
    text-align: center;
    font-size: 40px;
    color: #fff;
  }
  .drawerSubTitle {
    font-size: 14px;
    text-align: left;
    opacity: 0.5;
    @media (max-width: 958px) {
      text-align: center;
    }
  }
  .drawerSubTitle2 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
  .subHeader {
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-bottom: 0;
    position: relative;
    top: 10px;
  }
  .drawerContentSmall {
    font-weight: lighter;
    font-size: 12px;
  }
  .drawerContent {
    font-weight: lighter;
    font-size: 12px;
  }
  .drawerIcon {
    font-size: 40px;
  }
  .itemRow {
    text-align: right;
  }
  .headerRow {
    margin-bottom: 50px;
    @media (max-width: 958px) {
      margin-bottom: 0;
    }
  }
  .clickable {
    cursor: pointer;
    transition: 0.3s;
  }
  .clickable:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .is-watched {
    color: white !important;
  }
  .is-not-watched {
    color: rgba(255, 255, 255, 0.4);
  }
  .print-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .print-icon:hover {
    color: #fff;
  }

  .circle {
    width: 100px;
    height: 100px;
    background: #aaa;
    border-radius: 50%;
  }
  .infoWrap {
    padding-bottom: 18px;
    display: inline-block;
  }
  .icon-container {
    width: 125px;
    float: left;
  }
  .icon-description {
    max-width: 180px;
    padding-right: 0px;
    float: left;
    padding-left: 18px;
  }
  .centerContents {
    text-align: center;
  }
  .drawerIconOuter {
    width: 112px;
    height: 97px;
    display: inline-block;
    position: relative;
    .drawerHexagon {
      position: absolute;
      top: 0;
      left: 0;
    }
    .drawerIcon {
      position: absolute;
      top: 21px;
      left: 28px;
    }
  }
}

// Print
.letter {
  .infoDrawer {
    padding: 0;
  }
  .infoDrawer .is-not-watched {
    color: #000;
  }

  .info-drawer-container {
    display: block;
    overflow: hidden;

    .hexagon-container {
      width: 150px;
      padding-left: 0;
      float: left;
    }
    .subTitleContainer {
      float: left;
      width: 400px;
    }
  }

  .headerRow {
    margin-bottom: 20px;
  }

  .sidebar-print {
    display: block !important;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .drawerIconOuter {
      .drawerIcon {
        margin-left: -88px;
        top: -30px;
        left: auto;
        position: relative;

        &:before {
          font-size: 48px;
        }
      }
    }

    .innerDonutText {
      color: #444;
    }

    .drawerSubTitle {
      margin-bottom: 5px;
    }
    .drawerTitle {
      font-size: 40px;
      line-height: 1.2;

      span {
        line-height: 1;
      }
    }

    .icon-container {
      width: 125px;
      float: left;
    }
    .icon-description {
      width: 200px;
      padding-right: 10px;
      float: left;

      .drawerContent {
        font-size: 12px;
        margin-top: 10px;
      }
    }

    .grade-weighting-container {
      margin-bottom: 20px;
      padding: 20px;
      overflow: auto;
    }

    .indicator-wrapper {
      width: calc(50% - 2px);
      float: left;
      display: block;

      &:nth-child(2n + 1) {
        margin-right: 4px;
      }
    }

    .IndicatorButton {
      background: #fff !important;
      color: #333 !important;
      border: 1px solid #111;
      margin-bottom: 5px;

      .indicatorButtonScore {
        color: #000;

        .weight {
          color: #444;
        }
      }
    }

    .pageBreak {
      display: block;
      page-break-after: always;
    }

    .consideration-desc {
      overflow: auto;
      width: 100%;
      line-height: 1.5;
      display: block;
      margin-right: 0;

      p {
        margin-bottom: 20px;
      }

      .subHeader {
        font-weight: bold;
        font-size: 18px;
        margin-top: 30px;
      }
    }
    .action-btns {
      display: none;
    }
  }
}
