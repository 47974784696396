.recharts-tooltip-cursor {
  opacity: 0.2;
}
.recharts-default-tooltip {
  background: #31353d !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 5px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.custom-tooltip {
  background-color: #31353d;
  padding: 5px 15px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
}
.recharts-default-legend {
  text-align: left !important;
  padding-left: 50px !important;
  @media (max-width: 959px) {
    padding-left: 25px !important;
    font-size: 12px;
    .recharts-legend-item {
      margin: 5px;
      margin-top: 0px;
    }
  }
}
.recharts-legend-wrapper {
  @media (max-width: 959px) {
    margin-bottom: 25px !important;
  }
}
.areaChart {
  width: 95%;
  height: 100%;
  margin: auto;
  padding: 30px 0;
  position: relative;
  .areaHeader {
    position: relative;
    top: -10px;
    font-weight: bold;
    max-width: none;
  }
  .goalsBtn {
    position: absolute;
    top: 18px;
    right: 10px;
    z-index: 10;
    @media (max-width: 1100px) {
      top: 14px;
      right: 0px;
    }
  }
  .moveUp {
    top: -28px;
  }
  .recharts-surface {
  }
  .recharts-legend-item {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3;
    span {
      position: relative;
      top: 1px;
    }
  }
  .recharts-legend-item-text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }
  .recharts-legend-item:hover {
    opacity: 1;
  }
  .recharts-cartesian-axis-tick-value {
    fill: #fff;
    width: 50px;
    font-size: 14px;
  }
  .xAxis {
    .recharts-cartesian-axis-tick-value {
      transform: translateY(10px);
    }
  }
}
.horizontalChart {
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
  .recharts-legend-wrapper {
    width: 100% !important;
    left: 0 !important;
  }
}

.recharts-cartesian-axis-tick-value {
  fill: #fff;
  font-size: 14px;
}

// only when we have 4 bars
.isShowingPrevQuarters {
  &.quartersCount_1 {
    .recharts-cartesian-axis-tick-line {
      transform: translateY(5px);
    }
  }
  &.quartersCount_2 {
    .recharts-cartesian-axis-tick-line {
      transform: translateY(9px);
    }
  }
  &.quartersCount_3 {
    .recharts-cartesian-axis-tick-line {
      transform: translateY(15px);
    }
  }
  &.quartersCount_4 {
    .recharts-cartesian-axis-tick-line {
      transform: translateY(22px);
    }
  }

  // For indicator comparison chart to squish the bars within one group closer
  .recharts-layer.recharts-bar-rectangle:nth-child(4n + 1) {
    .recharts-rectangle {
      transform: translateY(3px);
    }
  }

  .recharts-layer.recharts-bar-rectangle:nth-child(4n + 3) {
    .recharts-rectangle {
      transform: translateY(-3px);
    }
  }
  .recharts-layer.recharts-bar-rectangle:nth-child(4n + 4) {
    .recharts-rectangle {
      transform: translateY(-6px);
    }
  }
}

.chartHelper {
  display: flex;
  flex: 1;
  .select {
    width: 100%;
    justify-content: start;
    display: flex;
    flex-direction: column;
    &:first-child {
      padding-right: 20px;
    }
  }
}
@media (max-width: 767px) {
  .chartHelperWrapper {
    flex-direction: column;
  }
  .chartHelper {
    flex-direction: column;
    .select {
      margin-top: 20px;
      justify-content: start;
    }
  }
}

@media print {
  .horizontalChart,
  .areaChart,
  .horizontal-chart-container {
    .recharts-legend-item-text {
      color: black;
    }
    .goalsBtn {
      display: none;
    }
    .recharts-cartesian-axis-tick-value,
    .recharts-cartesian-axis-tick {
      text {
        fill: black;
      }
      fill: black;
    }
  }

  .areaChart {
    margin-left: 0;
    width: 650px;
    max-width: 100%;

    .recharts-surface {
      max-width: 100%;
    }
  }
}
