.CityScoresPage {
  padding: 0px 15px;
  font-family: Heebo;
  color: #fff;

  .city-scores-top-strip {
    display: flex;
    justify-content: space-between;

    .navigation {
      color: white;
      text-decoration: none;
      font-family: Heebo;

      .nav-icon {
        margin-right: 10px;
      }

      .city-name-print {
        display: none;
      }
    }
    .action-icons {
      margin-top: 20px;
      margin-bottom: 20px;
      a {
        color: #939394;
      }
      .icon-print-icon {
        margin-left: 20px;
        font-size: 18px;
        color: #fff;
        opacity: 0.5;
        cursor: pointer;
      }
      .icon-print-icon:hover {
        opacity: 1;
      }
      .download-icon {
        font-size: 24px;
        color: #fff;
        opacity: 0.5;
        vertical-align: top;
      }
      .download-icon:hover {
        opacity: 1;
      }
    }
  }

  .CityScoresHeader {
    font-size: 28px;
  }
  .dimensionGridHeader {
    width: 100%;
    display: inline-block;
    border-bottom: 2px solid #777;
    padding: 10px;
    font-weight: bold;
  }
  .scoreSort {
    display: block;
    width: 100%;
    text-align: right;
    margin-right: 16px;
    .prefix {
      padding-right: 8px;
    }
  }
  .dropdown-select {
    padding-left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    :focus {
      border-radius: 4px !important;
    }
  }
}

@media print {
  .pageContent {
    padding-top: 0px;
  }

  .page-spacing {
    padding-top: 8%;
  }

  .CityScoresPage {
    padding: 8%;
    padding-top: 0;

    .city-scores-top-strip {
      .navigation {
        .city-name-print {
          display: inline;
        }
      }
    }

    .navigation span {
      color: black;
    }

    .nav-icon {
      display: none;
    }

    .city-name-print {
      color: black;
      display: inline;
    }

    .dimension-dashboard-container {
      padding: 0;

      .dimScoresHeader {
        white-space: nowrap;
        padding-left: 0px;
        padding-right: 0;
        padding-bottom: 5px;
      }
    }
    .dimensionScore {
      padding-bottom: 10px;
    }

    .dimension-scores-container {
      display: block;

      .hex-div {
        width: 33%;

        .HexScorecardWrapper {
          .considerationName {
            font-size: 14px;
          }
        }
      }
    }

    .action-icons {
      display: none;
    }

    .scoreSort {
      display: none;
    }

    .considerationName {
      color: black;
    }
  }
}
