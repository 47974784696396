@import '../../assets/mixins.scss';

.ranking-name-btn span {
  text-transform: none;
}
.sorting-table {
  height: 600px;
  position: relative;
  padding-top: 56px;

  @include styled-scroll-bar;
  .stickyHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .tableBody {
    height: calc(600px - 56px);
    min-height: 0;
    display: block;
    overflow: auto;
    .fullWidth {
      display: inline-table;
      width: 100%;
    }
  }
  .rankingsHeader {
    text-align: left;
    padding: 0;
    vertical-align: middle;
    padding-left: 10px;
    span {
      font-weight: bold;
      display: block;
      font-size: 16px;
      color: white;
    }
  }

  th.rankingsScore {
    padding-right: 0;
    min-width: 112px;
    position: absolute;
    border: none;
    right: 0;
    top: 18px;
  }

  .ranking-name-btn {
    padding: 0 !important;
    text-align: left;
    justify-content: flex-start;
    width: 100%;
  }
  .ranking-name-btn:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}

.moveContentsRight {
  text-align: right !important;
}

@media print {
  .score-table-wrapper.CitiIQCard {
    height: 400px;
  }
  .sorting-table {
    max-height: none;
    overflow: auto;
    padding-left: 10px;
    padding-right: 10px;

    thead {
      tr {
        height: 40px;
      }
      th,
      .rankingsHeader {
        background: rgba(0, 0, 0, 0.1);
        border-bottom: none;
        padding-bottom: 0px !important;
        span {
          color: #444;
        }
      }
      th.rankingsScore {
        position: static;
        text-align: right;
        padding-bottom: 0 !important;
      }
    }
    tbody,
    .tableBody {
      width: 100%;
      display: table-row-group;

      th,
      td {
        padding-left: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 2px;
        padding-top: 2px;

        button {
          min-height: 30px;
        }

        .colored-score-div {
          height: 25px !important;
          width: 40px !important;
        }
      }

      tr {
        width: 100%;
        height: 30px;
      }

      th {
        width: 80%;
      }
    }
    .ranking-name-btn {
      color: #444;
    }
  }
}
