.CityProfile {
  color: #fff;
  font-family: Heebo;
  .navigation {
    color: white;
    text-decoration: none;
  }
  h1 {
    .icon-print-icon {
      float: right;
      opacity: 0.5;
      transition: 0.5s;
      &:hover {
        opacity: 1;
      }
    }
  }
  .chartTitle {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    top: 15px;
  }
  .marginTop {
    margin-top: 7px;
  }
  .chart {
    padding: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .recharts-cartesian-axis-tick-value {
      fill: #fff;
      width: 50px;
      font-size: 12px;
    }
    .vertTick {
      color: red !important;
    }
  }
  .location {
    margin-bottom: 0;
    font-size: 32px;
  }
  .coordinates {
    opacity: 0.5;
    font-size: 18px;
    margin-top: 0px;
  }
  .title {
    font-size: 24px;
  }
  .description {
    color: rgba(255, 255, 255, 0.73);
    font-size: 16px;
    line-height: 24px;
    max-width: 640px;
  }
  .attrDiv {
    min-height: 180px;
  }
  .logoCard {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .circle {
      border: 0.5px solid #9ec73d;
      opacity: 0.5;
      width: 130px;
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      .circleLogo {
        color: #9ec73d;
        font-size: 40px;
      }
      .moveLeft {
        position: relative;
        left: -5px;
      }
    }
    .attribute-title {
      margin-top: 7px;
    }
    @media (max-width: 599px) {
      padding: 15px;
      .circle {
        width: 75px;
        height: 75px;
        .circleLogo {
          font-size: 30px;
        }
      }
    }
  }
  .attributeWrapper {
    padding: 22px !important;
    @media (max-width: 599px) {
      text-align: center;
      padding: 15px !important;
    }
    .attributeTitle {
      opacity: 0.3;
      font-size: 14px;
      font-weight: bold;
    }
    .attributeValue {
      font-size: 24px;
    }
    .shrunk {
      font-size: 16px;
    }
  }
  .profileDonutWrapper {
    margin: 8px 0px;
    .infoQuadrant {
      text-align: center;
      padding: 32px 20px !important;
      display: block;
      justify-content: center;
      align-items: center;
      .donutTitle {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 18px;
        display: block;
      }
      .donutSubText {
        font-size: 14px;
        opacity: 0.5;
        display: block;
        padding-top: 15px;
      }
    }
  }
}

.city-map-container {
  display: flex !important;
  justify-content: center;
}

.city-map {
  width: 600px;
  max-width: 100%;
  max-height: 600px;
  padding-bottom: 40px;
}

@media print {
  .pageBreak {
    display: block;
    page-break-after: always;
  }
  .addSpacing {
    padding-top: 8%;
    display: block;
  }
  .CityProfile {
    color: #444;
    .navigation {
      color: #444;
      display: none;
      h1 {
        margin-bottom: 0;
      }
      svg {
        display: none;
      }
    }
    .CitiIQCard {
      max-height: unset;
    }
    .city-details {
      .location {
        margin-top: 0;
      }
      .title {
        margin-bottom: 0;
      }
      .description {
        margin-top: 0;
        color: #444;
      }
    }
    .chart {
      .recharts-responsive-container {
        max-width: 650px;
        .recharts-cartesian-axis-tick-value {
          fill: black;
        }
      }
    }
  }
  .pageContent {
    color: #444;
    padding: 8%;
  }
}
.mapboxgl-ctrl-bottom-right {
  display: none;
}
.mapboxgl-ctrl-bottom-left {
  display: none;
}
.mapboxgl-map {
  border-radius: 4px;
}
