@mixin styled-scroll-bar {
  &,
  &:hover {
    scrollbar-width: thin;
    scrollbar-height: thin;
    scrollbar-color: rgba(255, 255, 255, 0.15) transparent;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.15);
    border: 2px solid transparent;
    border-radius: 4px;
  }
}
