.HexScorecard {
  color: #fff;
  padding: 15px;
  position: relative;
  .score {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 27px;
  }
  .considerationName {
    position: absolute;
    top: 52px;
    right: 15px;
    font-size: 16px;
    max-width: 50%;
    text-align: right;
    line-height: 18px;
  }
  @media (max-width: 500px) {
    height: 70px;
    padding: 10px 8.5px;
    .icon {
      font-size: 30px;
      margin-top: 5px;
    }
    .score {
      top: 5px;
      font-size: 23px;
    }
    .considerationName {
      top: 38px;
      max-width: 70%;
    }
  }
}
.HexScorecardWrapper {
  height: 100px;
  @media (max-width: 500px) {
    height: 70px;
  }
}

@media print {
  .HexScorecard {
    -webkit-print-color-adjust: exact;
    .trdiv,
    .tldiv,
    .brdiv,
    .bldiv {
      background: white !important;
    }
  }
  div.hex-div {
    width: 50%;
    display: inline-block;
    max-width: 50%;
  }
}
