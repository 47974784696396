.Login {
  text-align: center;
  padding-top: 200px;
  font-family: Heebo;
  .loginBackground {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .loginGridWrapper {
    width: 75%;
    display: inline-block;
  }
  .loginDiv {
    border: 1.5px solid rgba(100, 100, 100, 0.5);
    width: 700px;
    display: inline-block;
    position: relative;
    border-radius: 5px;
    padding-bottom: 35px;
    .siteLogo {
      position: absolute;
      height: 50px;
      top: -80px;
      left: 0px;
    }
    .registerDiv {
      color: #fff;
      position: absolute;
      top: -65px;
      opacity: 0.7;
      right: 0;
      .registerButton {
        color: #fff;
        border: 1px solid #fff;
        border-radius: 5px;
        margin-left: 10px;
        .disable-link-styles {
          text-decoration: none;
          color: inherit;
        }
      }
    }
    .loginHeader {
      color: #eee;
      font-size: 25px;
      margin-top: 35px;
      margin-bottom: 0;
    }
    .loginSubHeader {
      color: #eee;
      font-size: 15px;
      font-weight: lighter;
      margin-top: 10px;
    }
    .loginInput {
      padding: 15px;
      width: 100%;
      background: rgba(0, 0, 0, 0);
      border: 1.5px solid rgba(100, 100, 100, 0.5);
      border-radius: 5px;
      color: #eee;
      font-size: 16px;
      transition: 0.3s;
    }
    .loginInput:focus {
      outline: none;
      border: 1.5px solid rgba(170, 209, 48, 0.5);
    }
    .loginButton,
    .backButton {
      color: #fff;
      background: #aad130;
      font-weight: bold;
      text-transform: none;
      padding: 15px;
      width: 100%;
    }
    .backButton {
      background: #b7b7b7;
    }
    .forgotPass {
      padding-top: 16px;
      color: #fff;
      text-decoration: none;
      span {
        cursor: pointer;
        opacity: 0.75;
        transition: opacity 0.2s;
      }
      span:hover {
        opacity: 1;
      }
    }
  }
  @media (max-width: 767px) {
    padding-top: 145px;
    .loginDiv {
      border: none;
      width: 100%;
    }
    .siteLogo {
      margin-left: 15%;
    }
    .registerDiv {
      text-align: left;
      bottom: -30px !important;
      top: inherit !important;
      left: 15%;
    }
  }
}

.errorDiv {
  max-height: 100px;
  overflow: auto;
  border: 1px solid rgba(100, 100, 100, 0.5);
  margin: 25px;
  margin-top: -10px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  top: 25px;
  @media (max-width: 599px) {
    margin-left: 0;
    margin-right: 0;
  }
  .errorMessage {
    color: #ea5b5b;
  }
}
.languageSelectorDiv {
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  .selector {
    max-width: 180px;
  }
}
