.IndicatorButton {
  background: #31353d !important;
  padding: 15px 8px !important;
  color: rgba(255, 255, 255, 0.73) !important;
  width: 100%;
  height: 55px;
  position: relative;
  .icon {
    position: absolute;
    left: 7px;
    font-size: 22px;
    opacity: 0.2;
  }
  .indicatorButtonScore {
    font-size: 18px !important;
    position: absolute;
    left: 50px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    .weight {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.73);
      font-weight: normal;
    }
  }
  .indicatorButtonName {
    position: absolute;
    right: 10px;
    text-align: right;
    font-size: 14px;
    text-transform: none;
    max-width: 60%;
  }
}
