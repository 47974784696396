.quarter-select {
  background: linear-gradient(0deg, #31353d, #31353d), #1e1d1f;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  color: #9ec73d;
  font-size: 14px;
  &.quarter-select--loading {
    opacity: 0.5;
    &:after {
      content: 'loading...';
      position: absolute;
      top: 6px;
      left: 9px;
      font-size: 12px;
      line-height: 19px;
    }
    .quarter-select__select {
      opacity: 0;
    }
  }
}
.quarter-select__select {
  padding-left: 12px !important;
}
