.MilestoneDialog {
  font-family: Heebo;
  color: #fff;
  padding: 15px;
  width: 85%;
  max-width: 500px;
  .header {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 20px;
  }
  .button {
    max-width: 40px;
    padding: 8px;
    min-width: 40px;
  }
  .input {
    width: 100%;
  }
  .save {
    color: #5881c4;
    text-transform: uppercase;
    float: right;
  }
  .inputHeader {
    color: #777;
    font-size: 12px;
    font-weight: bold;
  }
  .cancel {
    color: #999;
    text-transform: uppercase;
  }
  .hideOverflow {
    overflow: hidden;
  }
  @media (max-width: 959px) {
    margin: 0 !important;
  }
}
