.IndicatorComparisonPage {
  color: #fff;
  font-family: Heebo;
  h1 {
    font-family: Heebo;
  }
  .top-strip {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .navigation {
      color: white;
      text-decoration: none;
    }
    .action-icons {
      margin-top: 20px;
      margin-bottom: 20px;

      .icon-print-icon {
        margin-left: 20px;
        font-size: 25px;
        color: #939394;
        transition: 0.5s;
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }
    }
  }
  .chartHeader {
    padding-left: 20px;
    margin-bottom: 0;
  }
  .num-cell {
    width: 80px;
  }
  .data-cell,
  .num-cell {
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
  }
  .delete-milestone-button {
    position: relative;
    top: -4px;
  }
  .data-cell {
    .RemoveCircle {
      color: #ad2424;
      transition: color 0.3s;
      cursor: pointer;
      font-size: 18px;
      :hover {
        color: #dd2a2a;
      }
    }
  }
  .goalsBtn {
    top: -30px !important;
    right: -8px;
  }
  .create-milestone-button {
    background: transparent;
    border-radius: 3px;
    border: none;
    color: #4a90e2;
    font-family: Heebo;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    float: right;
    margin-right: 10px;
  }

  .grid-div {
    min-height: 500px;
    border-radius: 3px;
    background-color: #31353d;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    color: white;
    margin-bottom: 50px;
  }
  .comparisonChartDiv {
    height: 520px;
  }
}
@media print {
  .IndicatorComparisonPage {
    h1 {
      svg {
        display: none;
      }
    }

    .navigation span {
      color: black;
    }

    .chartHeader,
    .data-cell,
    .num-cell,
    th span {
      color: black;
    }
    .chartHeader {
      padding-left: 0;
    }

    .recharts-default-legend {
      float: left;
    }
    .action-icons {
      display: none;
    }
    .create-milestone-button {
      display: none;
    }

    .dimension-comparison-wrapper {
      padding-left: 24px;

      .areaChart {
        padding-bottom: 0;
      }

      .recharts-wrapper {
        max-width: 600px;

        svg {
          max-width: 600px;
        }
      }
    }

    .milestone-wrapper {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
    }
  }
}
