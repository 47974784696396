.SDGPage {
  color: #fff;
  font-family: Heebo;
  .sdg_container {
    flex-direction: column;
  }
  .navigation {
    text-decoration: inherit;
    color: inherit;
  }
  .sdg_images_container {
    @media (max-width: 499px) {
      justify-content: center;
      .sdg_image {
        width: 33.33%;
        max-width: 118.5px;
      }
    }
  }
  .highcharts-tooltip {
    z-index: 1;
    position: relative;
  }
  .sdg_mobile_hidden {
    display: none !important;
  }
  .sdg_mobile_chart_label {
    font-size: 14px;
    display: block;
    border-top: 4px solid #282729;
    margin: -4px -16px 5px;
    padding: 10px 20px;
  }
  .sdg_mobile_chart_legend {
    position: relative;
    padding-left: 16px;
    font-size: 16px;
    font-weight: 700;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -4px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }
    &:first-child {
      margin-top: 24px;
      &:before {
        background-color: rgba(246, 74, 74, 1);
      }
    }
    &:last-child {
      margin-bottom: 24px;
      &:before {
        background-color: rgba(128, 140, 255, 1);
      }
    }
  }
  .highcharts-legend-item {
    &:first-child {
      .highcharts-point {
        fill: rgba(246, 74, 74, 1);
      }
    }
    &:last-child {
      .highcharts-point {
        fill: rgba(128, 140, 255, 1);
      }
    }
    &:not(.highcharts-legend-item-hidden) {
      span {
        color: white !important;
        opacity: 1 !important;
      }
    }
    &.highcharts-legend-item-hidden {
      span {
        text-decoration: line-through !important;
      }
    }
  }
  .highcharts-xaxis-labels {
    & > span {
      display: block;
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 16px;
      text-align: right;
      &::before,
      &::after {
        content: '';
        right: 0;
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 100%;
        position: absolute;
        z-index: 0;
      }
      &:before {
        top: 50%;
        margin-top: -9px;
        background-color: rgba(246, 74, 74, 1);
      }
      &::after {
        bottom: 50%;
        margin-bottom: -9px;
        background-color: rgba(128, 140, 255, 1);
      }
    }
  }
}
