.ForceLogoutDialog {
  padding: 30px;
  color: #fff;
  font-family: Heebo;
  position: relative;
  margin: 0 !important;
}

.content {
  text-align: center;
  width: 100%;
  margin: 20px 0;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px 0;
}

.logoutButton {
  color: #fff !important;
  background: #aad130 !important;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px;
}
