@media print {
  .donutWrapper {
    span.innerDonutText {
      color: black;
    }
  }
  .DimensionDashboard div.toggleGoals {
    display: none;
  }
}
.donutWrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  .innerDonutText {
    color: #fff;
    position: relative;
    top: 2px;
    font-family: Heebo;
  }
}
.innerDonut {
  position: absolute;
  top: 0px;
}
.donutTooltipOuter {
  opacity: 1;
  background: #31353d !important;
  border-radius: 0px !important;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2) !important;
  width: 165px;
  position: relative;
  left: 30px;
  .donutTooltip {
    height: 40px;
    display: flex;
    align-items: center;
    .leftTooltip {
      float: left;
      width: 110px;
      font-size: 18px;
      line-height: 22px;
      .subText {
        font-size: 12px;
        color: #aaa;
        display: block;
        line-height: 12px;
      }
    }
    .rightTooltop {
      float: right;
      width: 65px;
    }
  }
}
.show {
  opacity: 1 !important;
}
