.list-item:nth-child(even) {
  background: rgba(0, 0, 0, 0.1);
}
.list-item:nth-child(odd) {
  background: rgba(0, 0, 0, 0);
}
.list-item {
  font-family: Heebo;
  cursor: pointer;
  transition: background 0.2s;
}
.list-item:hover {
  background: rgba(0, 0, 0, 0.18);
}
.bottom_border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.watch-list {
  max-height: 450px;
  background-color: #31353d;
  padding: 0;
  .primary {
    font-size: 16px;
  }
  .secondary {
    font-size: 12px;
  }
  .header {
    font-weight: bold;
  }
  .sticky-header {
    padding-top: 6px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  .sticky-item {
    display: inline-block;
  }
  .sticky-btn-div {
    display: inline-block;
    float: right;
  }

  .watch-list-btn {
    color: #5d92f4;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    position: relative;
    top: -12px;
    left: 8px;
  }
  .remove-icon {
    color: #ad2424;
    transition: 0.3s;
    cursor: pointer;
    font-size: 18px;
  }
  .remove-icon:hover {
    color: #dd2a2a;
  }
  .emptyWatchedList {
    width: 100%;
    text-align: center;
    color: #999;
    padding: 0 10px;
    padding-bottom: 35px;
    svg {
      display: flex;
      width: 100%;
      font-size: 40px;
      height: 60px;
      opacity: 0.5;
      margin-top: 30px;
      align-items: center;
      justify-content: center;
    }
    span {
      margin-top: 30px;
      display: block;
    }
  }
}
