.Home-logo {
  height: 45px;
}

.Home-intro {
  font-size: large;
}

.button {
  background: red;
}
