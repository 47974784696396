.colored-score-div {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 25px;
  border-radius: 5px;
}

.colored-score-value {
  align-self: center;
}
