.register {
  text-align: center;
  padding-top: 200px;
  font-family: Heebo;
  overflow: auto;
  height: 100%;
  &.register--inline {
    padding: 0;
    margin: -8px; // to balance the spacing for its Mui Grid chidren
    .registerDiv {
      width: unset;
      border: unset;
      padding: 0;
      margin: 8px; // to balance the spacing for its Mui Grid chidren
    }
    .registerGridWrapper {
      width: 100%;
    }
  }

  .errorDiv {
    max-height: 260px;
    overflow: auto;
    border: 1px solid rgba(100, 100, 100, 0.5);
    margin: 25px;
    margin-top: -10px;
    margin-bottom: 50px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    top: 25px;
    @media (max-width: 599px) {
      margin-left: 0;
      margin-right: 0;
    }
    .errorMessage {
      color: #ea5b5b;
    }
  }

  .registerBackground {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .registerGridWrapper {
    width: 75%;
    display: inline-block;
  }
  .registerDiv {
    border: 1.5px solid rgba(100, 100, 100, 0.5);
    width: 700px;
    display: inline-block;
    position: relative;
    border-radius: 5px;
    padding-bottom: 35px;
    .siteLogo {
      position: absolute;
      height: 50px;
      top: -80px;
      left: 0px;
    }
    .loginDiv {
      color: #fff;
      position: absolute;
      top: -65px;
      opacity: 0.7;
      right: 0;
      .loginButton {
        color: #fff;
        border: 1px solid #fff;
        border-radius: 5px;
        margin-left: 10px;
      }
    }
    @media (max-width: 599px) {
    }
    .registerHeader {
      color: #eee;
      font-size: 25px;
      margin-top: 35px;
      margin-bottom: 0;
    }
    .bottomPadded {
      padding-bottom: 20px;
    }
    .registerSubHeader {
      color: #eee;
      font-size: 15px;
      font-weight: lighter;
      margin-top: 10px;
    }
    .registerInput {
      padding: 15px;
      width: 100%;
      background: rgba(0, 0, 0, 0);
      border: 1.5px solid rgba(100, 100, 100, 0.5);
      border-radius: 5px;
      color: #eee;
      font-size: 16px;
      transition: 0.3s;
    }
    .registerInput:focus {
      outline: none;
    }
    .registerInput:focus {
      outline: none;
      border: 1.5px solid rgba(170, 209, 48, 0.5);
    }
    .errorInput {
      border-color: #ea5b5b !important;
    }
    .registerButton,
    .backButton {
      color: #fff;
      background: #aad130;
      font-weight: bold;
      text-transform: none;
      padding: 15px;
      width: 100%;
    }
    .backButton {
      background: #b7b7b7;
    }
  }
  @media (max-width: 767px) {
    padding-top: 70px;
    height: stretch;
    .registerDiv {
      border: none;
      width: 100%;
    }
    .registerHeader {
      font-size: 20px !important;
      font-weight: bold;
    }
    .siteLogo {
      margin-left: 0;
      top: -30px !important;
    }
    .registerDiv {
      text-align: left;
      bottom: -30px !important;
      top: inherit !important;
      width: 70%;
    }
    .registerGridWrapper {
      width: 100%;
    }
    .loginDiv {
      text-align: left;
      bottom: -60px !important;
      top: inherit !important;
      left: 0;
      width: 100% !important;
      margin-bottom: 25px;
    }
  }
}
.errorDialog {
  padding: 15px;
  font-family: Heebo;
  color: #fff;
  .errorDiv {
    max-height: 300px;
    overflow: auto;
    border: 1px solid rgba(100, 100, 100, 0.5);
    margin: 25px;
    margin-top: -10px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    top: 25px;
    @media (max-width: 599px) {
      margin-left: 0;
      margin-right: 0;
    }
    .errorMessage {
      color: #ea5b5b;
    }
  }
}

.termsOfUseDialog {
  padding: 40px;
  color: #fff;
  font-family: Heebo;

  .termsOfUseSpace {
    margin: 20px 0;
  }

  .termsOfUseTitle {
    font-size: 1.2em;
    font-weight: bold;
  }
  .termsOfUseTitle {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 10px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
    padding: 15px;
  }
}

.termsOfUse {
  color: #fff;
}
.termsOfUseClick {
  color: #aad130;
  cursor: pointer;
  transition: color 0.5s;
}
.termsOfUseClick:hover {
  color: #6c8b0f;
}
