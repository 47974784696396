.DataComparisonPage {
  .selected {
    color: #5e9bff !important;
  }
  .recharts-legend-wrapper {
    @media (max-width: 700px) {
      width: 100% !important;
      left: 0 !important;
    }
  }
  .summaryDonut {
    padding: 10px;
  }
  .donut {
    display: inline-block;
    position: relative;
    top: -13px;
    .innerDonutText {
      top: 0px;
      position: relative;
    }
  }
  .text {
    display: inline-block;
    padding-top: 10px;
    margin-left: 15px;
    .name {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .recharts-wrapper {
    position: relative;
    top: -25px;
  }
}

.grid-container {
  min-height: 500px;
}
.data-comparison-container {
  .horizontal-chart-container {
    max-height: 700px;
  }
}

.top-strip {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .navigation {
    color: white;
    text-decoration: none;
  }

  .action-icons {
    margin-top: 20px;
    margin-bottom: 20px;

    .icon-print-icon {
      margin-left: 20px;
      font-size: 25px;
      color: #939394;
    }
  }
}

.on-print-mode {
  .horizontal-chart-container {
    max-width: 650px;
  }
}

@media print {
  .tabs-selector span {
    color: black;
    opacity: 0.7;
  }
  .selectedTab span {
    opacity: 1;
  }

  .tabs-selector {
    display: none;
  }

  div.consideration-comparison-wrapper {
    padding-left: 24px;
    box-shadow: none;

    .recharts-legend-wrapper {
      transform: translateY(-10px);
      text-align: right;
      width: 100% !important;

      ul.recharts-default-legend {
        display: inline-block;
        font-size: 12px;
        float: none;
      }
    }

    svg.recharts-surface {
      max-width: 600px;
    }

    .horizontal-chart-container {
      max-width: 650px;

      .recharts-cartesian-axis-ticks {
        margin-right: 5px;
      }
    }
  }

  .current-page-dimension-summary.pageContent {
    padding: 0;
    padding-top: 8%;

    .navigation {
      width: 100%;
      display: block;
    }
    h1 {
      padding-left: 8%;
    }
  }
}
