.admin-icon {
  float: right;
  opacity: 0.6;
  transition: 0.5s;
  color: #fff;
  width: 24px;
  height: 24px;
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
.adminPage-navigation {
  display: inline-block;
}
.admin-table {
  width: 100%;
  display: table;
  font-family: Heebo;
  border-spacing: 0;
  border-collapse: collapse;
  td,
  th {
    border: none;
  }
  .table-header {
    th {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }
  }
  tbody {
    th {
      color: #b6bbcf;
      font-size: 14px;
      line-height: 20px;
    }
    td:not(:first-child) {
      color: #ffffff;
      font-size: 14px;
      line-height: 16px;
    }
    .dropdown-select {
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      width: 192px;
      padding: 10px;
      font-size: 14px;
      line-height: 16px;
      box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.13);
    }
  }
}
